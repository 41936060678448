/**
 * MinersFactory | questions and answers page
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SubPage from "./../templates/subpage"
import SEO from "./../components/seo"

import "./../components/faq.css"

const FaqPage = () => {
    const data = useStaticQuery(graphql`
        query FaqItemsQuery {
            allFaqJson {
                edges {
                    node {
                        ques
                        answ
                    }
                }
            }
        }
    `)

    function QuesAnswBox(props) {
        return (
            <section className="list-group-item">
                <h5>{props.qapair.node.ques}</h5>
                <p>{props.qapair.node.answ}</p>
            </section>
        )
    }

    return (
        <SubPage>
            <SEO
                title="Häufig gestellte Fragen"
                description="Du hast eine Frage zu unserem Server oder dazugehörigen Diensten? Möglicherweise wurde sie bereits beantwortet! Hier findest du Antworten auf Fragen zum Betreitt auf den Server, Entbannungs-Themen und mehr!"
            />
            <div className="container">
                <p>
                    Du hast eine Frage zu unserem Server oder dazugehörigen
                    Diensten? Möglicherweise wurde sie bereits beantwortet!
                    Anbei findest du die häufigst gestellten Fragen:
                </p>
                <div className="list-group">
                    {data.allFaqJson.edges.map((quesansw, i) => (
                        <QuesAnswBox key={i} qapair={quesansw} />
                    ))}
                </div>
            </div>
        </SubPage>
    )
}

export default FaqPage
